<template>
    <modal ref="modalPesoVolumetrico" titulo="Seleccionar medida volumétrica" @guardar="cambioVolumen">
        <div v-for="(volumen, v) in volumenes" :key="v" class="row mx-0">
            <div class="col-auto d-middle mb-3 text-general">
                <input v-model="id_volumen" :value="volumen.id" type="radio" class="option-input radio black" name="Efectivo" /> {{ `${volumen.nombre} - ${volumen.volumen}` }}
            </div>
            <div class="col-auto px-2">
                <el-tooltip placement="bottom" effect="light">
                    <div slot="content" style="max-width:300px;">
                        <div class="row mx-0 mb-2">
                            <p class="col-12 text-general2 f-14 pl-2 mb-2">
                                {{ volumen.descripcion }}
                            </p>
                            <div class="col-12 px-2">
                                <img v-for="(data2,d) in volumen.imagenes" :key="d" :src="data2.imagen_mini" class="obj-cover border br-10 mr-2 mb-2" width="50" height="50" />
                            </div>
                        </div>
                    </div>
                    <span>
                        <i class="icon-info-circled-alt text-general f-18" />
                    </span>
                </el-tooltip>
            </div>
        </div>
    </modal>
</template>

<script>
import CedisProductos from '~/services/cedis/cedis_productos'

export default {
    data(){
        return {
            id_volumen: 1,
            id_producto: 1,
            url_image: 'https://img.lalr.co/cms/2017/04/12033800/Productos_Vendidos_CocaCola1204.jpg?size=xl&ratio=r40_21',
            volumenes: []
        }
    },
    methods: {
        toggle(params){
            this.getVolumenesProductos()
            this.id_volumen = params.id_volumen
            this.id_producto = params.id_producto
            this.$refs.modalPesoVolumetrico.toggle();
        },
        async getVolumenesProductos(){
            try {
                const {data} = await CedisProductos.getVolumenesProductos()
                this.volumenes = data.volumenes
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioVolumen(){
            try {

                const params = {
                    id_producto: this.id_producto,
                    id_volumen: this.id_volumen
                }

                const {data} = await CedisProductos.cambioVolumen(params)
                this.$emit('actualizar', data.volumen)
                this.$refs.modalPesoVolumetrico.toggle();
                this.notificacion('Mensaje',data.Message,'success')

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
